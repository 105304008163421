<template>
  <SaveChanges @cancel-clicked="onCancelClicked()"  v-if="showConfirmEdit" :isChannel="false"/>
  <div class="d-flex flex-column px-5 mt-3">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex justify-content-start align-items-center">
        <div @click="onViewCampaignClicked()" class="main-btn d-flex justify-content-center align-items-center"
             v-bind:class="getSelectedBoxColour('View')">View Campaigns
        </div>
        <div @click="onAddCampaignClicked()" class="main-btn d-flex justify-content-center align-items-center mx-3"
             v-bind:class="getSelectedBoxColour('Add')"><img class="add-icon mx-1"
                                                             src="../../../../assets/products/add-icon.png" alt="">Add
          Campaign
        </div>
      </div>
      <div v-if="showSaveChanges" class="d-flex justify-content-start align-items-center">
        <div @click="onSaveChagesClicked()" class="main-btn d-flex justify-content-center align-items-center green">Save changes
        </div>
        <div @click="onCancelClicked()" class="main-btn d-flex justify-content-center align-items-center mx-3">Cancel
        </div>
      </div>
    </div>
    <div v-if="showViewCampaigns" class="d-flex mx-2 flex-column w-100 mt-4 border-bottom">
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="d-flex flex-column justify-content-center align-items-center box-width" v-for="(dropDown, index) in dropsDowns">
          <DropDownBox :isChannel="false" :dropDownOptions="getDropDownOptions(dropDown)" :dropDownName="getDropDownName(dropDown)" :index=index />
        </div>
      </div>
    </div>
    <div v-if="showViewCampaigns" class="d-flex mt-2 mb-2 count-txt">
      <i>Campaign Count: <span><b>{{ this.campaignsTable.length }}</b></span></i>
    </div>
    <div v-if="showViewCampaigns && campaignsTable.length > 0" class="d-flex justify-content-evenly mt-3 w-100 box-height" v-for="(campaign, index) in campaignsTable" v-bind:class="getSelectedCampaignClass(campaign)">
      <spa v-if="!campaign.isSelected" class="col-2 d-flex justify-content-center align-items-center box-width"><img @click="onCampaignClicked(campaign)" class="edit-icon"
                                                                                                                      src="../../../../assets/products/edit-icon.png"
                                                                                                                      alt="">{{ campaign.CampaignId }}</spa>
      <span @click="onCloseIconClicked(index)" v-if="campaign.isSelected" class="col-2 d-flex justify-content-center align-items-center box-width close-span"><img class="edit-icon"
                                                                                                src="../../../../assets/products/close-icon.png"
                                                                                                alt="">{{ campaign.CampaignId }}</span>

      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.CampaignName" v-if="campaign.isSelected" type="text" :placeholder="campaign.CampaignName" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ campaign.CampaignName }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.ChannelName" v-if="campaign.isSelected" type="text" :placeholder="campaign.ChannelName" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ campaign.ChannelName }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.Country" v-if="campaign.isSelected" type="text" :placeholder="campaign.Country" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ campaign.Country }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <input @input="validatePercentage(index)"  @change="onInputChanged(campaign)" v-model="campaignsTable[index].PostbackPercentage" v-if="campaign.isSelected" type="text" :placeholder="campaign.PostbackPercentage" class="d-flex justify-content-center align-items-center input-bar">
        <span v-if="!campaign.isSelected" class="d-flex justify-content-center align-items-center">{{ campaign.PostbackPercentage }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <input @input="onNominalCpaChanged()" @change="onInputChanged(campaign)" v-model="campaignsTable[index].NominalCpa" v-if="campaign.isSelected" type="text" :placeholder="campaign.NominalCpa" class="d-flex justify-content-center align-items-center input-bar">
        <span v-if="!campaign.isSelected" class="d-flex justify-content-center align-items-center">{{ campaign.NominalCpa }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.Network" v-if="campaign.isSelected" type="text" :placeholder="campaign.Network" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ campaign.Network }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.Platform" v-if="campaign.isSelected" type="text" :placeholder="campaign.Platform" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ campaign.Platform }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.Product" v-if="campaign.isSelected" type="text" :placeholder="campaign.Product" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ campaign.Product }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <!--        <input v-model="selectedCampaign.Product" v-if="campaign.isSelected" type="text" :placeholder="campaign.Product" class="d-flex justify-content-center align-items-center">-->
        <span class="d-flex justify-content-center align-items-center">{{ campaign.Currency }}</span>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <span class="url-text box-width-url">{{ campaign.ProductUrl }}</span>
        <button class="copy-btn" @click="copyUrl(campaign.ProductUrl)" title="Copy URL">
          <font-awesome-icon :icon="['far', 'copy']" />
        </button>
      </div>
      <div class="box-width d-flex justify-content-center align-items-center">
        <span class="url-text box-width-url">{{ campaign.PromotionalProductUrl }}</span>
        <button v-if="campaign.PromotionalProductUrl" class="copy-btn" @click="copyUrl(campaign.PromotionalProductUrl)" title="Copy URL">
          <font-awesome-icon :icon="['far', 'copy']" />
        </button>
      </div>

    </div>
    <div v-if="campaignsTable.length === 0 && !showAddCampaign" class="d-flex flex-column align-items-center col-11">
      <img class="col-2 mt-3" src="../../../../assets/no-campaign-found-icon.png">
      <div class="text-center mt-2 col-5">We couldn't find any campaign IDs that match you criteria. Please try creating
        a new campaign or adjusting your search parameters
      </div>
    </div>
  </div>
  <div v-if="showAddCampaign" class="d-flex mt-5 left-margin add-campaign-main">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column m-3 field-box">
        <h5>Campaign Name</h5>
        <input v-model="campaignName" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Campaign Name"/>
      </div>
      <div class="d-flex flex-column m-3  field-box">
        <h5 class="p-2">Channel Name</h5>
        <div @click="toggleDropdown('Channel')" class="dropdown-box d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center p-2">{{channelName.name}}</div>
          <span :class="{'arrow-down': !isChannelDropdownOpen, 'arrow-up': isChannelDropdownOpen}"></span>
        </div>
        <AddCampaignDropDown @update-dropdown="toggleDropdown('Channel')" :dropDownOptions="channelDropDownOptions" v-if="isChannelDropdownOpen" header="Channel"/>
      </div>
      <div class="d-flex flex-column m-3  field-box">
        <h5 class="p-2">Product ID</h5>
        <div @click="toggleDropdown('ProductId')" class="dropdown-box d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center p-2">{{productId.name}}</div>
          <span :class="{'arrow-down': !isProductIdDropdownOpen, 'arrow-up': isProductIdDropdownOpen}"></span>
        </div>
        <AddCampaignDropDown @update-dropdown="toggleDropdown('ProductId')" :dropDownOptions="productIdDropDownOptions" v-if="isProductIdDropdownOpen" header="ProductId"/>
      </div>
      <div class="d-flex flex-column m-3  field-box">
        <h5 class="p-2">MNO</h5>
        <input v-model="network" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Network" readonly/>
      </div>
      <div class="d-flex flex-column m-3  field-box">
        <h5 class="p-2">Promo Product</h5>
        <div @click="toggleDropdown('promoProduct')" class="dropdown-box d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center p-2">{{ promoProduct.name }}</div>
          <span :class="{'arrow-down': !isPromoProductIDDropdownOpen, 'arrow-up': isPromoProductIDDropdownOpen}"></span>
        </div>
        <AddCampaignDropDown @update-dropdown="toggleDropdown('promoProduct')" :dropDownOptions="promoProductIdDropDownOptions" v-if="isPromoProductIDDropdownOpen" header="PromotionalProductUrl"/>
      </div>
      <div class="d-flex align-items-center mt-5">
        <div @click="onCreateCampaignClicked()" v-if="!isFormNotCompeted()" class="main-button orange d-flex justify-content-center align-items-center">Create CampaignId</div>
        <div v-if="isFormNotCompeted()" class="main-button orange-black d-flex justify-content-center align-items-center">Create CampaignId</div>
        <div @click="onCancelCampaignClicked()" class="main-button d-flex justify-content-center align-items-center">Cancel</div>
      </div>
    </div>

    <div class="d-flex flex-column mx-5">
      <div class="d-flex flex-column m-3 field-box">
        <h5>Postback %</h5>
        <input v-model="postBackPercentage" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Postback %"/>
      </div>
      <div class="d-flex flex-column m-3 field-box">
        <h5>Nominal CPA</h5>
        <input v-model="nominalCpa" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Nominal CPA"/>
      </div>
      <div class="d-flex flex-column m-3  field-box">
        <h5 class="p-2">Platform</h5>
        <div @click="toggleDropdown('Platform')" class="dropdown-box d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-center align-items-center p-2">{{platform.name}}</div>
          <span :class="{'arrow-down': !isPlatformDropdownOpen, 'arrow-up': isPlatformDropdownOpen}"></span>
        </div>
        <AddCampaignDropDown @update-dropdown="toggleDropdown('Platform')" :dropDownOptions="platformDropDownOptions" v-if="isPlatformDropdownOpen" header="Platform"/>
      </div>
      <div class="d-flex flex-column m-3  field-box">
        <h5 class="p-2">Country</h5>
        <input v-model="country" class="d-flex justify-content-center align-items-center h-100 p-2" type="text" placeholder="Country" readonly/>
<!--        <div @click="toggleDropdown('Country')" class="dropdown-box d-flex justify-content-between align-items-center">-->
<!--          <div class="d-flex justify-content-center align-items-center p-2">{{country.name}}</div>-->
<!--          <span :class="{'arrow-down': !isCountryDropdownOpen, 'arrow-up': isCountryDropdownOpen}"></span>-->
<!--        </div>-->
<!--        <AddCampaignDropDown @update-dropdown="toggleDropdown('Country')" :dropDownOptions="countryDropDownOptions" v-if="isCountryDropdownOpen" header="Country"/>-->
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import CampaignHubService from "@/services/campaignHubService";
import DropDownBox from "@/views/components/marketing/DropDownBox";
import AddCampaignDropDown from "@/views/components/marketing/AddCampaignDropDown";
import SaveChanges from "@/views/components/marketing/SaveChanges";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faCopy);

export default {
  name: "CampaignsView",
  components: {
    DropDownBox,
    AddCampaignDropDown,
    SaveChanges,
    FontAwesomeIcon
  },
  data() {
    return {
      campaignsTable: [],
      nominalCpa: 0,
      postBackPercentage: 0,
      selectedMainButton: 'View',
      dropsDowns: [],
      showSaveChanges: false,
      showViewCampaigns: true,
      showAddCampaign: false,
      campaignName: 'Campaign Name',
      isChannelDropdownOpen: false,
      channelDropDownOptions: [],
      isCountryDropdownOpen: false,
      countryDropDownOptions: [],
      isProductIdDropdownOpen: false,
      productIdDropDownOptions: [],
      isnetworkDropdownOpen: false,
      networkDropDownOptions: [],
      isPlatformDropdownOpen: false,
      platformDropDownOptions: [],
      promoProductIdDropDownOptions: [],
      isPromoProductIDDropdownOpen: false,
    }
  },
  computed: {
    ...mapState(['isLoading', 'productConfig', 'userData', 'campaignChannelTable', 'channelName', 'country', 'productId', 'network', 'platform', 'showConfirmEdit', 'editedCampaigns', 'promoProduct'])
  },
  watch: {
    campaignChannelTable(oldValue, newValue) {
      this.campaignsTable = this.campaignChannelTable
    },
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setCampaignChannelTable', 'setCountry', 'setProductId', 'setNetwork', 'setPlatform', 'setChannelName', 'pushEditedCampaign', 'setShowConfirmEdit']),
    onCloseIconClicked(index) {
      this.campaignsTable[index].isSelected = false
     if (this.editedCampaigns.length < 1) this.showSaveChanges = false
    },
    onInputChanged(campaign) {
      this.pushEditedCampaign(campaign)
    },
    onNominalCpaChanged() {
      this.showSaveChanges = true
    },
    validatePercentage(index) {
      const value = this.campaignsTable[index].PostbackPercentage;
      if (value > 100) {
        this.campaignsTable[index].PostbackPercentage = 100 // Enforce max 100
      } else if (value < 0 || isNaN(value)) {
        this.campaignsTable[index].PostbackPercentage = 0
      }
      this.showSaveChanges = true
    },
    async onCreateCampaignClicked() {
      await CampaignHubService.createCampaign(this.campaignName, this.channelName.parameter, this.country.parameter, this.postBackPercentage, this.nominalCpa, this.network.parameter, this.platform.parameter, this.productId.parameter, this.userData.LoginName, this.promoProduct.parameter)

      const response = await this.setCampaigns();
      if (response.result) {
        this.showAddCampaign = false;
        this.showViewCampaigns = true;
      }
      await this.getCampaignDropDowns();
    },
    onCancelCampaignClicked() {
      this.setChannelName('Channel Name')
      this.setCountry('Country')
      this.setProductId('Product ID')
      this.setNetwork('Network')
      this.setPlatform('Platform')
      this.nominalCpa = 0
      this.postBackPercentage = 0
      this.campaignName = 'Campaign Name'
    },
    isFormNotCompeted() {
      if (this.channelName.name === 'Channel Name') return true
      if (this.country.name === 'Country') return true
      if (this.country.name === 'Country') return true
      if (this.productId.name === 'Product ID') return true
      if (this.network.name === 'Network') return true
      if (this.platform.name === 'Platform') return true
      if (this.campaignName === 'Campaign Name') return true


    },
    toggleDropdown(dropDown) {
      console.log(dropDown)
      if (dropDown === 'Channel')  this.isChannelDropdownOpen = !this.isChannelDropdownOpen;
      if (dropDown === 'Country')  this.isCountryDropdownOpen = !this.isCountryDropdownOpen;
      if (dropDown === 'ProductId')  this.isProductIdDropdownOpen = !this.isProductIdDropdownOpen;
      if (dropDown === 'Network')  this.isnetworkDropdownOpen = !this.isnetworkDropdownOpen;
      if (dropDown === 'Platform')  this.isPlatformDropdownOpen = !this.isPlatformDropdownOpen;
      if (dropDown === 'promoProduct')  this.isPromoProductIDDropdownOpen = !this.isPromoProductIDDropdownOpen;
    },
    onViewCampaignClicked() {
      this.showAddCampaign = false
      this.showViewCampaigns = true
      this.selectedMainButton = 'View'
    },
    async onAddCampaignClicked() {
      this.showViewCampaigns = false
      this.showAddCampaign = true
      this.selectedMainButton = 'Add'
      await this.setAddCampaignDropDowns();
    },
    clearDropDownOptions() {
      this.channelDropDownOptions = []
      this.countryDropDownOptions = []
      this.productIdDropDownOptions = []
      this.networkDropDownOptions = []
      this.platformDropDownOptions = []
      this.promoProductIdDropDownOptions = []
    },
    async setAddCampaignDropDowns() {
      this.clearDropDownOptions();
      const dropsDowns = await CampaignHubService.getCampaignDropDowns(true);
      if (dropsDowns.length < 1) return
      dropsDowns.forEach((dropDown) => {
        dropDown.forEach((option) => {
          console.log(option)
          if (option.Header === 'Channel Name') this.channelDropDownOptions.push({name: option.DisplayName, parameter: option.Parameter})
          if (option.Header === 'Country') this.countryDropDownOptions.push({name: option.DisplayName, parameter: option.Parameter})
          if (option.Header === 'Product') this.productIdDropDownOptions.push({name: option.DisplayName, parameter: option.Parameter, network: option.MNO, country: option.Country})
          if (option.Header === 'MNO') this.networkDropDownOptions.push({name: option.DisplayName, parameter: option.Parameter})
          if (option.Header === 'Platform') this.platformDropDownOptions.push({name: option.DisplayName, parameter: option.Parameter})
          if (option.Header === "PromotionalProductUrl") this.promoProductIdDropDownOptions.push({name: option.DisplayName, parameter: option.Parameter})
        })
      })
    },

    async onSaveChagesClicked() {
      this.setShowConfirmEdit(true);
      this.showSaveChanges = false;
    },
    async onCancelClicked() {
      this.campaignsTable.forEach((campaign) => campaign.isSelected = false)
      this.showSaveChanges = false
      await this.setCampaigns();
    },
    getSelectedCampaignClass(campaign) {
      if (campaign.isSelected) return 'selected'
    },
    onCampaignClicked(campaign) {
        campaign.isSelected = true
    },
    getDropDownName(dropDown) {
      if (dropDown.length > 0) return this.getFirstKey(dropDown[0])
    },
    getDropDownOptions(dropDown) {
      return dropDown.map(item => {
        const displayNameString = item.DisplayName ? item.DisplayName.toString() : 0
        return {
          displayName: displayNameString,
          parameter: item.Parameter
        }
      });
    },
    getFirstKey(obj) {
      return obj.Header
    },
    async copyUrl(url) {
      try {
        await navigator.clipboard.writeText(url);
        console.log('URL copied:', url); // Temporary debugging
        alert('URL copied!');
      } catch (error) {
        console.error('Clipboard error:', error); // Important for debugging
        alert('Failed to copy URL.');
      }
    },
    getSelectedBoxColour(btn) {
      if (btn === this.selectedMainButton) return 'orange'
    },
    async setCampaigns() {
      const campaigns = await CampaignHubService.getCampaigns()
      this.campaignsTable = campaigns;
      this.setCampaignChannelTable(campaigns);
      return {result: true}
    },
    async getCampaignDropDowns() {
      const dropDowns = await CampaignHubService.getCampaignDropDowns(true);
      dropDowns.forEach((dropDown) => dropDown.selectedField = 'All')
      this.dropsDowns = dropDowns
    },
  },
  async beforeMount() {
    await this.getCampaignDropDowns();
    await this.setCampaigns();

  }
}
</script>

<style scoped>
.copy-btn {
  border: none;
  background: none;
  cursor: pointer;
  color: #555;
  transition: color 0.2s;
}
.url-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
  min-width: 0; /* Important for flex children to properly truncate */
  font-size: 14px;
  color: #1a73e8;
}

span {

}

.input-bar {
  width: 50%;
  text-align: center;
}
.add-campaign-main {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.buttons-position {
  position: absolute;
  bottom: 40px;
  z-index: -10;
}

.close-span {
  z-index: 200;
}
.main-button {
  width: 200px;
  height: 50px;
  border: solid gray 1px;
  margin-right: 20px;
  margin-left: 20px;
}
.bottom-buttons-position {
  position: relative;
  bottom: 100px;
  left: 50px;
}
.arrow-down,
.arrow-up {
  display: inline-block;
  margin-left: 8px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-right: 5px;
}

.arrow-down {
  border-top: 6px solid #EE5C36; /* Downward arrow */
}

.arrow-up {
  border-bottom: 6px solid #EE5C36; /* Upward arrow */
}

.left-margin {
  margin-left: 40px;
}
.field-box {
  width: 450px;
  height: 75px;
}
.dropdown-box {
  height: 100%;
  width: 100%;
  border: solid 1px gray;
}
.box-height {
  height: auto;
}
.box-width {
  width: 8%; /* Keep it flexible rather than setting both max-width & min-width */
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.box-width-url {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; /* Ensure it uses full available space */
  flex-grow: 1; /* Allows the text to take available space */
}

.box-width-url::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  padding-left: 5px;
}

.edit-icon {
  width: 15px;
  height: auto;
  margin-right: 10px;
}

.count-txt {
  font-size: 18px !important;
}

.main-btn {
  width: 150px;
  height: 50px;
  border: solid 1px black;
}

.orange {
  background-color: #EE5C36;
  color: white !important;
}

.orange-black {
  background-color: #A63E2D; /* Brownish-red background */
  color: rgba(255, 255, 255, 0.7); /* Semi-transparent white text */
}

.green {
  background-color: green;
  color: white !important;
}

.selected {
  background-color: #EE5C36;
}

.add-icon {
  height: 50%;
  width: auto;
}

.down-arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  height: 70%;
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
}

.selected-field-name-box {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-top: solid grey 1px;*/
  /*border-bottom: solid grey 1px;*/
}

.border-top-bottom {
  border-top: solid gray 1px;
  border-bottom: solid gray 1px;
  width: 100%;
}
</style>
