import {createStore} from 'vuex'

export default createStore({
    state: {
        userData: undefined,
        userProductAccess : undefined,
        isLoading: false,
        productConfig: undefined,
        reportingMetric: undefined,
        startingDateCalendar: undefined,
        endingDateCalendar: undefined,
        triggerNewChart: false,
        barChartDataUpdated: undefined,
        barChartToLoad: undefined,
        lineChartDataUpdated: undefined,
        lineChartToLoad: undefined,
        sideBarMenuVisible: false,
        campaignChannelTable: [],
        userSelectedCampaignOptions: [],
        channelName: {name: 'Channel Name'},
        country: 'Country',
        productId: {name: 'Product ID'},
        promoProduct: {name: 'Promo Product'},
        network: 'Network',
        platform: {name: 'Platform'},
        costCalculationtype: {name: 'Select Cost Type'},
        currency: {name: 'Select Currency'},
        selectedCampaigns: [],
        editedCampaigns: [],
        showConfirmEdit: false
    },
    mutations: {
        setShowConfirmEdit(state, showConfirmEdit) {
            state.showConfirmEdit = showConfirmEdit
        },
        setSelectedCampaigns(state, selectedCampaigns) {
            state.selectedCampaigns = selectedCampaigns
        },
        setEditedCampaigns(state, editedCampaigns) {
            state.editedCampaigns = editedCampaigns
        },
         pushEditedCampaign(state, editedCampaigns) {
            state.editedCampaigns.push(editedCampaigns)
        },
        setCurrency(state, currency) {
            state.currency = currency
        },
        setCostCalculationtype(state, costCalculationtype) {
            state.costCalculationtype = costCalculationtype
        },
        setPlatform(state, platform) {
            state.platform = platform
        },
        setNetwork(state, network) {
            state.network = network
        },
        setProductId(state, productId) {
            state.productId = productId
        },
        setPromoProduct(state, promoProductId) {
            state.promoProduct = promoProductId
        },
        setCountry(state, country) {
            state.country = country
        },
        setChannelName(state, channelName) {
            state.channelName = channelName
        },
        setUserSelectedCampaignOptions(state, userSelectedCampaignOptions) {
            state.userSelectedCampaignOptions = userSelectedCampaignOptions
        },
         setCampaignChannelTable(state, campaignChannelTable) {
            state.campaignChannelTable = campaignChannelTable;
        },
        setUserData(state, userData) {
            state.userData = userData;
        },
        setUserProductAccess(state, userProductAccess) {
            state.userProductAccess = userProductAccess;
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setProductConfig(state, productConfig) {
            state.productConfig = productConfig
        },
        setReportingMetric(state, reportingMetric) {
            state.reportingMetric = reportingMetric
        },
        setStartingDateCalendar(state, startingDateCalendar) {
            state.startingDateCalendar = startingDateCalendar
        },
        setEndingDateCalendar(state, endingDateCalendar) {
            state.endingDateCalendar = endingDateCalendar
        },
        setTriggerNewChart(state, triggerNewChart) {
            state.triggerNewChart = triggerNewChart
        },
        setBarChartData(state, barChartDataUpdated) {
            state.barChartDataUpdated = barChartDataUpdated
        },
        setBarChartToLoad(state, barChartToLoad) {
            state.barChartToLoad = barChartToLoad
        },
        setLineChartData(state, lineChartDataUpdated) {
            state.lineChartDataUpdated = lineChartDataUpdated
        },
        setLineChartToLoad(state, lineChartToLoad) {
            state.lineChartToLoad = lineChartToLoad
        },
        setSidebarMenuVisible(state, sideBarMenuVisible) {
            state.sideBarMenuVisible = sideBarMenuVisible
        },
    },
    actions: {},
    modules: {}
})
