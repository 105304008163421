import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import moment from "moment";

export default class CampaignHubService {
    static async getVoucherStats() {
        const response = await axios.post(`${environmentConfig.campaignHubService}/get-voucher-stats`, {
                fromDate: '2024-06-15',
                toDate: `${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
                productId: null,
            },
        )
        return response.data;
    }

        static async editCampaign(campaignId, postbackPercentage, updatedBy, nominalCpa) {
        const response = await axios.post(`${environmentConfig.campaignHubService}/marketing/update-campaign`, {
            campaignId,
            postbackPercentage,
            updatedBy,
            nominalCpa
        });
        return response.data;
    }

    static async editChannel(campaignId, postbackPercentage, updatedBy) {
        const response = await axios.post(`${environmentConfig.campaignHubService}/marketing/update-campaign`, {
            campaignId,
            postbackPercentage,
            updatedBy
        });
        return response.data;
    }

    static async getCampaignDropDowns(showAllAttributes) {
        const response = await axios.post(`${environmentConfig.campaignHubService}/marketing/get-campaign-dropdown`, {
            showAllAttributes
        });
        return response.data;
    }

    static async getChannelDropDowns(showAllAttributes) {
        const response = await axios.post(`${environmentConfig.campaignHubService}/marketing/get-channel-dropdown`, {
            showAllAttributes
        });
        return response.data;
    }

    static async getCampaigns(channelId, countryId, networkId, platformId, productId, postbackPercentage, nominalCpa, campaignId) {
        const response = await axios.post(`${environmentConfig.campaignHubService}/marketing/get-campaign`, {
            channelId,
            countryId,
            networkId,
            platformId,
            productId,
            postbackPercentage,
            nominalCpa,
            campaignId
        });
        return response.data;
    }
    static async getChannels(costCalculationTypeId, currencyId, channelId, channelName, subscriptionPostbackUrl, billingPostbackUrl, clickId) {
        const response = await axios.post(`${environmentConfig.campaignHubService}/marketing/get-channel`, {
            costCalculationTypeId,
            currencyId,
            channelId,
            channelName,
            subscriptionPostbackUrl,
            billingPostbackUrl,
            clickId
        });
        return response.data;
    }

    static async createCampaign(campaignName, channelId, countryId, postbackPercentage, nominalCPA, networkId, platformId, productId, createdBy, promotionalProductId) {
        const response = await axios.post(`${environmentConfig.campaignHubService}/marketing/create-campaign`, {
            campaignName,
            channelId,
            countryId,
            postbackPercentage,
            nominalCPA,
            networkId,
            platformId,
            productId,
            createdBy,
            promotionalProductId
        });
        return response.data;
    }

    static async createChannel(channelName, subscriptionPostbackUrl, billingPostbackUrl, costCalculationTypeId, currencyId, clickId, createdBy, publisher, amountParam) {
        const response = await axios.post(`${environmentConfig.campaignHubService}/marketing/create-channel`, {
            channelName,
            subscriptionPostbackUrl,
            billingPostbackUrl,
            costCalculationTypeId,
            currencyId,
            clickId,
            createdBy,
            publisher,
            amountParam
        });
        return response.data;
    }
}
